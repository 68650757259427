 import React from 'react'
 import { Container, Row, Tab, Tabs, Accordion, Col} from 'react-bootstrap'
 import './Faq.css'
 import { Link } from "react-router-dom";
 import { useLayoutEffect } from 'react';
 function Faq() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
}, []);
   return (
     <> 
<section className='bnr'>
<Container>
  <Row>
    <h2 className='text-center'>Faq</h2>
  </Row>
</Container>
</section>
<section className='faq'>
<Container>
  <Row className='faq-space'>
  <Tabs
      defaultActiveKey="Admins"
      transition={false}
      id="noanim-tab-example"
      className="mb-5"
    >
      <Tab eventKey="Admins" title="Admins">
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>1.How are pickup and drop-off points assigned?</Accordion.Header>
        <Accordion.Body>
          <p> During the time of Passenger registration the pickup and drop-off points are assigned to the passengers. The passenger is then given a Smart Card to commute on shuttles using this platform.</p>
        <Row>
        <Col md='6'> 
<img src={require('../../assets/images/lap1.jpg')}  alt="banner" className='img-fluid'/>
 </Col>   
 <Col md='6'> 
<img src={require('../../assets/images/lap2.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
  
        </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>2.What are the regular notifications generated by Reido?</Accordion.Header>
        <Accordion.Body>
          <p>Reido is equipped with the feature to send instant notification messages that provide the current location of the shuttle vehicle with its estimated time of arrival (ETA) that enables passengers to plan for their daily commute. Pickup and Drop-off notifications are sent to the passengers through the Passenger App.
</p>
<p>Also, a notification is automatically generated as soon as a passenger reserves or cancels a seat or any amenity on the bus.
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header>3.Why am I not getting the real-time location of the shuttle vehicle?</Accordion.Header>
        <Accordion.Body>
          <p>It may be due to low GPS/ Network connectivity in the current location of the shuttle vehicle. The location on the map will get updated automatically as soon as the GPS/Network is available.

</p>
 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="11">
        <Accordion.Header>4. How to send a message to all the drivers and passengers?</Accordion.Header>
        <Accordion.Body>
          <p>You can send a message to all the drivers and passengers as push notification through the Admin Panel.</p>
          <p><b>Admin Panel > Quick Panel > Notifications > View All Notifications > Send Message</b></p>
          <Row>
        <Col md='6'> 
<img src={require('../../assets/images/lap3.jpg')}  alt="banner" className='img-fluid'/>
 </Col>   
 <Col md='6'> 
<img src={require('../../assets/images/lap4.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/lap5.jpg')}  alt="banner" className='img-fluid'/>
 </Col>   
 <Col md='6'> 
<img src={require('../../assets/images/lap6.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 

        </Row>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="12">
        <Accordion.Header>5.Can you upload all the details of passengers, drivers, vehicles etc. with a Bulk Upload feature?</Accordion.Header>
        <Accordion.Body>
          <p>Yes, there is a Bulk Upload feature available in Reido. You can bulk upload data through the Admin Panel of the application.</p>
          <p><b>Admin Panel > Quick Panel > Scheduler</b></p>
          <Row>
          <Col md='6'> 
<img src={require('../../assets/images/lap7.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/lap8.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="13">
        <Accordion.Header>6.Are the details of SOS alerts saved for auditing purposes?</Accordion.Header>
        <Accordion.Body>
          <p>Yes, all the SOS alerts are saved in the database with all the necessary details for future references.</p>
          <p>You can access them by following these steps:</p>
          <p><b>Admin Panel > SOS > View All</b></p>
          <Row>
          <Col md='6'> 
<img src={require('../../assets/images/lap9.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/lap10.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="14">
        <Accordion.Header>7.From where the admins can track the vehicles in real-time?</Accordion.Header>
        <Accordion.Body>
           
          <p>Vehicles can be tracked in real-time from the Admin Panel and the Central Team app.</p>
          <p>For Admin Panel:</p>
          <p><b>Live Map > Select Route</b></p>
          <Row>
          <Col md='6'> 
<img src={require('../../assets/images/lap11.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
         
          <p>For Central Team:</p>
          <p><b>Full View > Select Route</b></p>

          <Row>
          <Col md='6'> 
<img src={require('../../assets/images/mobz1.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/mobz2.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
          <br/>
           
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="15">
        <Accordion.Header> 8.Can the Material Movement be booked in advance?</Accordion.Header>
        <Accordion.Body>
          <p>Yes, material movements can be booked in advance by choosing the Planned option while creating Material Movements from the admin panel.</p>
          <p><b>Admin Panel > Material > Add Material Movement</b></p>
          <Row>
          <Col md='6'> 
<img src={require('../../assets/images/lap12.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/lap13.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
      </Tab>

      <Tab eventKey="Passengers" title="Passengers" >
      <Accordion defaultActiveKey="2">
      <Accordion.Item eventKey="2">
        <Accordion.Header>1. 	How does a passenger sign-up and start using Reido? </Accordion.Header>
        <Accordion.Body>
         <p>First the passenger needs to download the Reido App on their smartphone either from the Google Play Store or the App store. Then the passenger can sign-up using their registered phone number and an OTP. Now the passenger will be logged in and they can start using the application. 
</p>
<p><b>Download Reido > Open App > Enter phone number > Enter OTP</b></p>
<Row>
          <Col md='4'> 
<img src={require('../../assets/images/mobz3.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/mobz4.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/mobz5.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>2.How to reserve seats and how long is the reservation possible?
</Accordion.Header>
        <Accordion.Body>
         <p>A passenger can reserve a seat well in advance using the app’s reservation feature and use the facility to reserve that for one day, one week or one month.
</p>
<p><b>Ride Request > Reserve a Ride > Select Date Range > Select Pickup route > Submit</b></p>
<Row>
          <Col md='6'> 
<img src={require('../../assets/images/mobz6.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/mobz7.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>3. How many seats can a passenger reserve at a time?</Accordion.Header>
        <Accordion.Body>
         <p>The number of seats a passenger could reserve for a trip is only one, but the passenger can reserve other amenities along with reserving a seat.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>4. 	Is it possible for a passenger to avail shuttle service without prior reservation?</Accordion.Header>
        <Accordion.Body>
         <p>Yes. Reido also provides a provision to accommodate non-reserved passengers according to the availability of seats.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>5. How can the reservation be cancelled?</Accordion.Header>
        <Accordion.Body>
         <p>Passengers can cancel their reserved seats through the same app with ease any time before the trip begins.</p>
        <p><b>Passenger App > Ride History > My Reservation</b></p>
        <Row>
          <Col md='6'> 
<img src={require('../../assets/images/mobz8.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/mobz9.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>6.Why am I not getting notification sometimes?</Accordion.Header>
        <Accordion.Body>
         <p>The following are some of the reasons why you are not getting real-time notifications:</p>
        <p><b>-Low Power, Energy Saving, Do Not Disturb Mode</b></p>
        <p>Please make sure your phone’s battery is left with more than 20% of charge as some phones have a specific battery optimization that will stop notifications from showing.</p>
        <p>iOS – “Low Power Mode” is enabled. If in your <b>Settings > Battery > Low Power Mode</b> is enabled, this will reduce the rate at which notifications can be received or turn them off completely.
</p>
<p>iOS – “Do Not Disturb” is enabled. If in your <b>Settings > Do Not Disturb </b>is enabled, this can prevent notifications from being received.
</p>
<p>ANDROID – <b>Settings > Battery > Battery Optimization or Battery saver > Find Reido app</b> and press Don’t optimise</p>
<p>-Allow Push Notification permission</p>
<p>Please make sure push permissions are enabled for the Reido app on your device:</p>
<p>iOS – Check Reido app under <b>Settings > Notifications</b>
</p>
<p>ANDROID – Check the notification setting under <b>Settings > Apps > Reido</b>
</p>
<p>– Low Network/ Wi-Fi connectivity</p>
<p>Please check your internet connection and try by disabling and re-enabling it.
</p>
         
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>7. 	How is ETA calculated?</Accordion.Header>
        <Accordion.Body>
         <p>Our application and systems are equipped with premium google map services to identify right ETA by considering the vehicle’s current position and traffic. ETA is calculated based on the sequence of passenger stops in the route.
</p>
  
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>8.Why am I not getting the real-time location of the shuttle vehicle?</Accordion.Header>
        <Accordion.Body>
         <p>It may be due to low GPS/ Network connectivity in the current location of the shuttle vehicle. The location on the map will get updated automatically as soon as the GPS/Network is available.
</p>
         
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>9. How can the passengers track their commute vehicle?</Accordion.Header>
        <Accordion.Body>
         <p>The passengers can track their commute vehicle through the Passenger App.</p>
        <p><b>Login > Click on Track</b></p>
        <Row>
          <Col md='6'> 
<img src={require('../../assets/images/mobz10.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/mobz11.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header>10. How to reserve a shuttle?</Accordion.Header>
        <Accordion.Body>
          
        <p><b>Ride Request > Reserve a Ride > Select Date Range > Select Pickup route > Submit</b></p>
        <Row>
          <Col md='6'> 
<img src={require('../../assets/images/mobz12.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/mobz13.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="11">
        <Accordion.Header>11.How to request an Adhoc trip?</Accordion.Header>
        <Accordion.Body>
         
        <p><b>Ride Request > Ride Now > Select Source,Destination,Driver and Time > Submit
</b></p>
        <Row>
          <Col md='4'> 
<img src={require('../../assets/images/mobz12.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/mobz14.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/mobz15.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="12">
        <Accordion.Header>12.How to change your pickup and drop-off locations?</Accordion.Header>
        <Accordion.Body>
         <p>In case you are assigned a new work site, you have to place a location change request.</p>
        <p><b>Settings Panel > Change my location > Select your new pickup and drop-off points > Submit
</b></p>
        <Row>
          <Col md='4'> 
<img src={require('../../assets/images/mobz16.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/mobz17.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/mobz18.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
      </Tab>

      <Tab eventKey="Drivers" title="Drivers">
      <Accordion defaultActiveKey="5">
      <Accordion.Item eventKey="5">
        <Accordion.Header>1. How to login to the Driver App?</Accordion.Header>
        <Accordion.Body>
          <p>To login to the application first you have to register your device.</p>
          <p><b>Open Driver App > Admin Login > Enter Device name > Select Cluster > Register</b></p>
          <p>Note that the device name that you enter should begin with the alphabet “V” as the prefix followed by the Vehicle No. of your vehicle.</p>
          <Row>
          <Col md='6'> 
<img src={require('../../assets/images/dr1.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr2.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 
          <p className='mt15'>After registering your device, enter your registered mobile number followed by the OTP to complete the login process.</p>
          <br/>
 <Col md='6'> 
<img src={require('../../assets/images/dr3.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr4.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>2.How does the break fence work?</Accordion.Header>
        <Accordion.Body>
          <p>The break fence is defined by a radius of distance decided by the admin for a particular cluster. When a driver who is taking a break breaches this radius the admin will receive a “Break Fence Notification”.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>3.How to start a Trip?</Accordion.Header>
        <Accordion.Body>
       <p><b>Login > Enter Odometer reading > Submit the Checklist > Trip List > Select Trip > Click on Start Trip</b></p>
       <Row>
       <Col md='6'> 
<img src={require('../../assets/images/dr5.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr6.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr7.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr8.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>4. How to end a trip?</Accordion.Header>
        <Accordion.Body>
          <p>To end a trip the driver needs to first drop-off all the passengers. To start dropping-off passengers, click on ‘Enable Drop’. After all the passengers have been dropped you will receive a confirmation message to go to the ‘End Trip Page’
</p>
<p><b>Enable Drop > Click on OK > Click on End Trip</b></p>
<Row>
       <Col md='6'> 
<img src={require('../../assets/images/dr9.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr10.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>5. How to mark passenger attendance?</Accordion.Header>
        <Accordion.Body>
         <p><b>Start Trip > Click on ADD > Choose the passenger > Click on ADD </b></p>
         <p>Now the passenger will be added to the trip and they will tap their NFC card to record their attendance. </p>
         <Row>
       <Col md='6'> 
<img src={require('../../assets/images/dr11.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr12.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr13.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr14.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header>6.  Will the drivers be able to see the list of their upcoming trips?</Accordion.Header>
        <Accordion.Body>
         <p>Yes, the drivers can see the complete list of their upcoming trips from the driver application.</p>
         <p><b>Login > Click on “Upcoming trips”</b></p>
         <Row>
       <Col md='6'> 
<img src={require('../../assets/images/dr15.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr16.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="11">
        <Accordion.Header>7. Can a driver reject an adhoc trip?</Accordion.Header>
        <Accordion.Body>
         <p>Yes, drivers can reject adhoc trips given that they have  a valid reason.</p>
         <p><b>Login > Click on “Upcoming Trips” > Under the Adhoc trip, click on “Reject” > Select a reason > Submit</b></p>
         <Row>
       <Col md='4'> 
<img src={require('../../assets/images/dr15.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/dr16.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/dr17.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="12">
        <Accordion.Header>8. How to accept an Adhoc trip?</Accordion.Header>
        <Accordion.Body>
        <p><b>Login > Click on “Upcoming trips” > Under the Adhoc trip, click on “Accept” > Click “OK” to confirm the adhoc ride</b></p>
        <Row>
       <Col md='4'> 
<img src={require('../../assets/images/dr15.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/dr16.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/dr18.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="13">
        <Accordion.Header>9. Can the drivers send an SOS alert from the Driver’s App?</Accordion.Header>
        <Accordion.Body>
        <p>Yes, the driver’s app has a dedicated SOS button to send instant SOS alerts in the event of an emergency.</p>
        <p><b>Click on SOS Button > Select a Reason > Type a Message > Click on Send</b></p>
        <Row>
       <Col md='6'> 
<img src={require('../../assets/images/dr19.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr20.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="14">
        <Accordion.Header>10. Does the Driver’s App have a navigation tool?</Accordion.Header>
        <Accordion.Body>
         <p>Yes, the Driver App has a Map button to let them navigate through their Trip route.</p>
         <p><b>Start Trip> Click on Map Button</b></p>
         <Row>
       <Col md='6'> 
<img src={require('../../assets/images/dr21.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr22.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="15">
        <Accordion.Header>11. How to maintain the record of filling fuel?</Accordion.Header>
        <Accordion.Body>
        <p>There is a fuel-fill feature on the Driver App, through which you can maintain precise records of filling fuel.
</p>
<p><b>Login > Click on the three dots in the upper-right corner > Select Fuel Fill > Fill in the details > Click on Submit</b></p>
<Row>
       <Col md='6'> 
<img src={require('../../assets/images/dr23.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr24.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr25.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr26.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="16">
        <Accordion.Header>12.How to take breaks?</Accordion.Header>
        <Accordion.Body>
          <p>You can take your breaks using the Break  button in the Driver App. Kindly note that you can only take breaks when there are no trips assigned to you.
</p>
<p><b>Trip List Page > Click on the Break button</b></p>
<Row>
       <Col md='6'> 
<img src={require('../../assets/images/dr23.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='6'> 
<img src={require('../../assets/images/dr27.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="17">
        <Accordion.Header>13. Can I get technical support from the Driver App?</Accordion.Header>
        <Accordion.Body>
          <p>Yes, you can easily reach out to us from the Driver App.</p>
          <p><b>Click on the three dots in the upper-right corner > Click on Technical Support > Type your query > Click on Submit</b></p>
          <Row>
       <Col md='4'> 
<img src={require('../../assets/images/dr23.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/dr24.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
 <Col md='4'> 
<img src={require('../../assets/images/dr28.jpg')}  alt="banner" className='img-fluid'/>
 </Col> 
          </Row>
        </Accordion.Body>
      </Accordion.Item>
       
    </Accordion>
      </Tab>
      
    </Tabs>
  </Row>
</Container>
</section>

<section className='five'>
  <Container>
<Row>
 <Col md="12"> 
 <h2 className='text-center'>Want to know more?
<br/>
Let’s get in touch</h2>
<p className='text-center'> <Link to="/contact"><button className='contact-btn'>Contact Us</button></Link></p>
</Col>
</Row>
  </Container>
  </section>

     </>
   )
 }
 
 export default Faq