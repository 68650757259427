import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import './About.css'
import { Link } from "react-router-dom";
import { useLayoutEffect } from 'react';
function About() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <> 
<section className='bnr'>
<Container>
 <Row>
   <h2 className='text-center'>About Us</h2>
 </Row>
</Container>
</section>
<section className='abtz p-card'>
<Container>
 <Row>
<Col md='6'> 
<img src={require('../../assets/images/abt.png')}  alt="banner" className='img-fluid'/>
 </Col>   
 <Col md='6'> 
<h3><b>About Reido </b></h3>
<br/>
<p>Qaptive Technologies is a world-class software service provider that offers solutions for both SME and enterprise. We have expertise in designing and developing cutting-edge ridership applications for schools, organisations and transport providers. The team at Qaptive consists of experienced professionals with a knack for crafting innovative solutions aimed at solving real-world problems using technology. We always strive to develop cutting-edge solutions that provide businesses with a competitive edge in their respective sectors. Our products will help people save valuable time, money, and resources. The key areas that we focus on in all our applications are: Live tracking, Trip management, Trip attendance, Trip booking, Vehicle management etc.
</p>
 </Col>  
 </Row>
</Container>
</section>
<section className='how p-card2'>
  <Container>
  <h3 className='text-center'><b>How it Works</b></h3>
  <br/>
 <Row>
 <Col md='12'> 
  
 <p><b>Admins will create stops and routes and will assign these routes to the drivers. The trips will be generated on the basis of these routes.</b></p>
 <p><b>The driver will get a list of all the trips assigned to him on the Driver App.</b></p>
 <p><b>When the driver starts a trip, all the passengers who have booked seats on that particular route and those that have been assigned to that route will be notified on the Passenger App.</b></p>
 <p><b>A proximity notification is sent to the passengers when the vehicle nears the stop.</b></p>
 <p><b>The passengers then enter the vehicle and mark their status as "Picked" by tapping a smart card on an NFC reader.</b></p>
 <p><b>Meanwhile, the complete details of the trip progress will be monitored by the admins via the Admin Panel and the Central Team application.</b></p>
 <p><b>To drop-off the passengers, the driver will have to click on the "Enable Drop button" on the Driver App. The passengers will then exit the vehicle by tapping their smart cards on an NFC reader, and this will mark their status as "Dropped".</b></p>
 <p><b>When the last passenger is dropped, the Driver App will display the "End Trip page" from where the driver can end the current trip.</b></p>
 <p><b>During the course of the entire trip, all the aspects of the trip will be tracked in real-time by the admins via the Admin Panel of Reido.</b></p>
 </Col>
  
 </Row>
 </Container>
</section>

<section className='five'>
 <Container>
<Row>
<Col md="12"> 
<h2 className='text-center'>Want to know more?<br/>
Let’s get in touch</h2>
<p className='text-center'> <Link to="/contact"><button className='contact-btn'>Contact Us</button></Link></p>
</Col>
</Row>
 </Container>
 </section>

    </>
  )
}

export default About