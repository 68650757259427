import React from 'react'
import './Header.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";

const Header = () => {
  return (
 
     <Navbar expand="lg" sticky="top">
      <Container>
      <Link to="/home"><img src={require('../../assets/images/logo.png')}  alt="logo"/></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className=''>
          <Nav className=" ">
          <Link to="/home">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/benefits">Benefits</Link>
          <Link to="/contact">Contact Us</Link>
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link path='/faq'>About</Nav.Link>
            <Nav.Link href="#link">Benefits</Nav.Link>
            <Nav.Link href="#link">Contact Us</Nav.Link>   */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
     
  )
}

export default Header