 import React from 'react'
 import { Container, Row, Col, Form, FloatingLabel, Button} from 'react-bootstrap'
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faEnvelope, faLocationPin, faPhone,  } from '@fortawesome/free-solid-svg-icons'
 import Iframe from 'react-iframe'
 import './Contact.css'
 import { useLayoutEffect } from 'react';
 function Contact() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
}, []);
   return (
     <> 
<section className='bnr'>
<Container>
  <Row>
    <h2 className='text-center'>Contact Us</h2>
  </Row>
</Container>
</section>
<section className='faq p-card'>
<Container>
  <Row className='faq-space'>
  
  <Col md="4">
  <FontAwesomeIcon icon={faLocationPin} />
  <br/>
  <p>Qaptive Technologies Pvt. Ltd.<br/>
  6A-3, Jyothirmaya IT Building,<br/>
Infopark SEZ Phase-2, Kochi<br/>
Kerala, India – 682 303</p>


  </Col>
  <Col md="4">
  <FontAwesomeIcon icon={faPhone} />
  <br/>
  <p>+91 484 4855790</p>
  </Col>
  <Col md="4">
  <FontAwesomeIcon icon={faEnvelope} />
  
  <p>info@reido.com</p>
  </Col>

 <div className='dot-border'></div>
<Form>
<Row>
 <Col md="4">
 <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Control type="text" placeholder="Enter Name" />
        <Form.Text className="text-muted"></Form.Text>
      </Form.Group>
 </Col>
 <Col md="4">
 <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="email" placeholder="Enter email" />
        <Form.Text className="text-muted"></Form.Text>
      </Form.Group>
 </Col>
 <Col md="4">
 <Form.Group className="mb-3" controlId="formBasicContact">
        <Form.Control type="number" placeholder="Enter Contact No." />
        <Form.Text className="text-muted"></Form.Text>
      </Form.Group>
 </Col>
 <Col md="12">
 
      <FloatingLabel controlId="floatingTextarea2" label="Comments">
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
        />
      </FloatingLabel>
</Col>
<Col md="12">
  <br/>
  <Button className='float-end'>SUBMIT</Button>
  </Col>
 </Row>
 </Form>
  </Row>
  
 

 
</Container>
</section>

<Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15716.61457417178!2d76.3755274!3d10.0041648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa2e22ce1245f54ac!2sQaptive%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1664280236247!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>

     </>
   )
 }
 
 export default Contact