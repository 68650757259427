 import React from 'react'
import { Container, Row, Col,  Card } from 'react-bootstrap'
 import './Home.css'
 import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAppleAlt, faPlay,  } from '@fortawesome/free-solid-svg-icons'
import { useLayoutEffect } from 'react';
import { Link } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
 const Body = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
}, []);
   return (
     < >
<section className='one'>
  <Container fluid>
<Row>
 
<Col md="6" xs={{ order: 'last' }} lg={{ order: 'first' }} className='bnr-txt'>
  <h1><b>Experience Premium  <br/>
  Shuttle Management</b> </h1>
<p>Enjoy the benefits of a powerful <br/>
and intuitive ridership application </p>
<div > 
<a href="https://apps.apple.com/us/app/reido/id1638088110" target="_blank" rel="noreferrer">
<button> <FontAwesomeIcon icon={faAppleAlt} /> App Store </button>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.fifa.centralteam" target="_blank" rel="noreferrer">
<button><FontAwesomeIcon icon={faPlay} />Play Store</button>
</a>
</div>
</Col>
<Col md="6"  xs={{ order: 'first' }} lg={{ order: 'last' }}><img src={require('../../assets/images/bnr.png')}  alt="banner" className='img-fluid'/></Col>
  </Row>
  </Container>
</section>

<section className='two'>
  <Container>
<Row>
<Col md="6">
  <h2>Elevate your shuttle services to the next level</h2> 

<p className='text-justify'>Reido is a user-friendly and robust application that makes managing your passenger transport services easy and effective. It has a cutting-edge dashboard with features that will multiply the efficiency of your transport operations. Reido is a comprehensive shuttle management application that will enhance the productivity and effectiveness of your shuttle services with features like fleet optimization, live tracking, ad hoc trips, geofencing, material movement, and many more.</p>
<p className='text-justify'>It provides you with end-to-end support for your transportation requirements, including your passenger’s mobility needs and your organisation’s material movements. This is achieved through an integrated network of components, which are the Admin Panel (Web), Central Team (Web, Android), Driver App (Android) and the Passenger App (Android, iOS).</p>
</Col>
<Col md="6"> </Col>
</Row>
  </Container>
  </section>

  <section className='three'>
  <Container fluid>
<Row>
<Col md="4">
  <h2 className='text-light'>Scalable to adapt to any user group  </h2>
<br/> 
<p className='text-justify text-light'>Reido is a completely cloud-based solution, which allows it to easily scale up or down and integrate with user groups of any size. Be it employees of a company, travellers in a tour group, or adhoc passengers, Reido is a smart shuttle management software that can cater to the mobility needs of a variety of passenger groups.
</p>
<p className='text-justify text-light'>Reido is a scalable application that can be deployed and used in a number of different vehicles whether large or small for several use cases.

</p>
</Col>
<Col md="8"> 
<Carousel responsive={responsive}>
  <div>
  <Card style={{ width: '19.2rem' }}>
      <Card.Img variant="top"  img src={require('../../assets/images/car.png')}  alt="banner" className='img-fluid'/>
      <Card.Body>
        <Card.Title>Premium Car</Card.Title>
        <Card.Text>
        6 seater vehicles for adhoc passengers
        </Card.Text>
      </Card.Body>
    </Card>
  </div>
  <div>
  <Card style={{ width: '19.2rem' }}>
  <Card.Img variant="top"  img src={require('../../assets/images/lemo.png')}  alt="banner" className='img-fluid'/>
      <Card.Body>
        <Card.Title>Limousine car</Card.Title>
        <Card.Text>
        8 seater vehicles for VIP guests

        </Card.Text>
      </Card.Body>
    </Card>
  </div>
  <div> <Card style={{ width: '19.2rem' }}>
  <Card.Img variant="top"  img src={require('../../assets/images/bus.png')}  alt="banner" className='img-fluid'/>
      <Card.Body>
        <Card.Title>Premium Buses</Card.Title>
        <Card.Text>
        50 seater vehicles for tour groups
        </Card.Text>
      </Card.Body>
    </Card></div>
  <div> <Card style={{ width: '19.2rem' }}>
  <Card.Img variant="top"  img src={require('../../assets/images/van.png')}  alt="banner" className='img-fluid'/>
      <Card.Body>
        <Card.Title>Vans</Card.Title>
        <Card.Text>
        12 seater vehicles for employees

        </Card.Text>
      </Card.Body>
    </Card></div>
</Carousel></Col>
</Row>
  </Container>
  </section>


  <section className='four'>
  <Container>
<Row>
<Col md="6">
<Card style={{ width: '100%' }} className='ml'>
<Card.Img variant="top"  img src={require('../../assets/images/fon.png')}  alt="banner" className='img-fluid'/>
      <Card.Body>
        <Card.Title>Mobile Applications</Card.Title>
        <Card.Text>
        Reido has three mobile applications: the Driver App (Android), the Passenger App (Android & iOS) and the Central Team App (Android).
 
 The Driver App is an advanced application for drivers to navigate through their assigned routes, manage trips and send SOS alerts.
 
        </Card.Text>
      </Card.Body>
    </Card>
</Col>
<Col md="6"> 
<Card style={{ width: '100%' }} className='ml'>
       
      <Card.Img variant="top"  img src={require('../../assets/images/lap.png')}  alt="banner" className='img-fluid'/>
      <Card.Body>
        <Card.Title>Web</Card.Title>
        <Card.Text>
        The Admin Panel is a core component of Reido and provides a comprehensive web interface for the transport admins to plan, execute, and monitor trips. This platform will enable them to supervise and manage all aspects of the entire transport service.

        </Card.Text>
      </Card.Body>
    </Card>
</Col>
</Row>
  </Container>
  </section>

  <section className='five'>
  <Container>
<Row>
 <Col md="12"> 
 <h2 className='text-center'>Want to know more? <br/>Let’s get in touch
 </h2>
<p className='text-center'> <Link to="/contact"><button className='contact-btn'>Contact Us</button></Link></p>
</Col>
</Row>
  </Container>
  </section>

     </>
   )
 }
 


 export default Body