import logo from './logo.svg';
import './App.css';

import Footer from './components/footer/Footer';
import Routes from './router/index'
import Header from './components/header/Header';


function App() {
  return (
    <div className="App">
      <Header />
     <Routes />
       <Footer />
    </div>
  );
}

export default App;
