import React from 'react'
import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className='p-card '>
           <Col md="3">
           <img src={require('../../assets/images/logo-black.png')}  alt="banner" className='img-fluid f-logo'/>
           <p>A premium ridership application to upgrade your shuttle management.</p>
           </Col>
           <Col md="3">
            <h4>Address</h4>
            <p>Qaptive Technologies Pvt. Ltd.<br/>
  6A-3, Jyothirmaya IT Building,<br/>
Infopark SEZ Phase-2, Kochi<br/>
Kerala, India – 682 303</p>
           </Col>
           <Col md="3">
            <h4>Quick Links</h4>
            <ul>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/benefits">Benefits</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
            </Col>
           <Col md="3">
           <h4>Leagel</h4>
            <ul>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
           </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer