import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import './Benefits.css'
import { Link } from "react-router-dom";
import { useLayoutEffect } from 'react';
function Benefits() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <> 
<section className='bnr'>
<Container>
 <Row>
   <h2 className='text-center'>Benefits</h2>
 </Row>
</Container>
</section>
<section className='abtz p-card'>
<Container>
 <Row>
<Col md='6'> 
<img src={require('../../assets/images/b1.png')}  alt="banner" className='img-fluid'/>
 </Col>   
 <Col md='6'> 
<h3><b>Organisations</b></h3>
<p><b>Leverage the efficiency of software-driven shuttle management</b></p>
<br/>
<p>Raido makes it simple for organisations to track and monitor their fleet of vehicles in real-time, providing them with greater visibility of their transportation services. It also gives them the ability to plan, schedule and execute their trips more efficiently. With an extensive array of high-end features, Reido ensures a guaranteed increase in the overall performance of your shuttle services.
</p>
<ul>
  <li>Live Tracking</li>
  <li>Vehicle Management</li>
  <li>Route Optimisation</li>
  <li>Driver Timesheet</li>
  <li>Comprehensive Reports</li>
  <li>Define Geofence</li>
  <li>Breach alerts</li>
  <li>SOS Management</li>

   
</ul>
 </Col>  
 </Row>
</Container>
</section>

<section className='how abtz p-card'>
<Container>
 <Row> 
 <Col md='6'> 
<h3><b>Passengers</b></h3>
<p><b>Hassle-free shuttle booking and tracking</b></p>
<br/>
<p>Reido’s Passenger App makes it incredibly convenient for passengers to do everything from booking a shuttle ride to an ad hoc trip to changing pickup locations. Passengers can experience a smooth shuttle service with Reido’s many passenger-friendly features.</p>
<ul>
  <li>Book Shuttle rides</li>
  <li> Book Adhoc rides</li>
  <li>Track the vehicle</li>
  <li>Trip Notifications</li>
  <li>Trip Timelines</li>
  <li>Call and Chat with the Driver</li>
</ul>
 </Col>  
 <Col md='6'> 
<img src={require('../../assets/images/b2.png')}  alt="banner" className='img-fluid'/>
 </Col>  
 </Row>
</Container>
</section>

<section className='how abtz p-card'>
<Container>
 <Row> 
 <Col md='6'> 
<img src={require('../../assets/images/b3.png')}  alt="banner" className='img-fluid'/>
 </Col>  
 <Col md='6'> 
<h3><b>Drivers</b></h3>
<p><b>Trip management and navigation made easy</b></p>
<br/>
<p>With a host of driver-centric features, Reido ensures the maximum productivity of drivers. The dedicated Driver App was developed in a way that it solves the everyday issues faced by the drivers in their daily trip operations. It will enable the drivers to complete their daily tasks smoothly, ensuring a streamlined shuttle service.
</p>
<ul>
  <li>Trip Management</li>
  <li>Accurate Passenger Attendance</li>
  <li>Route Navigation</li>
  <li>Map Update with Live Tracking</li>
  <li>Chat with Admin</li>
  <li>SOS Alerts </li>

</ul>
 </Col>  
 </Row>
</Container>
</section>



<section className='five'>
 <Container>
<Row>
<Col md="12"> 
<h2 className='text-center'>Want to know more?
<br/>
Let’s get in touch</h2>
<p className='text-center'> <Link to="/contact"><button className='contact-btn'>Contact Us</button></Link></p>
</Col>
</Row>
 </Container>
 </section>

    </>
  )
}

export default Benefits