 import React from 'react'
 import { Container, Row, Col} from 'react-bootstrap'
 import './privacy.css'
 import { Link } from "react-router-dom";
 import { useLayoutEffect } from 'react';
 function Privacy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
}, []);
   return (
     <> 
<section className='bnr'>
<Container>
  <Row>
    <h2 className='text-center'>Privacy & Policy</h2>
  </Row>
</Container>
</section>
<section className='faq p-card'>
<Container>
  <Row className='faq-space'>
 <p>This Privacy Policy explains how we collect, process, use, share and protect information about you and your passengers. It also tells you how you can access and update your information and make certain choices about how your information and your passengers’ information are used. In this policy, “personal information” means any information by which you can be identified or contacted, such as your passengers’ details or your organisation name, email address, telephone number, etc. Reido collects personal information when communicating or interacting with you or your passengers through Mobile App or website as well as via mailings, telephone, or in person.</p>
 <div className='dot-border'></div>
 <h4>What information do we collect?
                    </h4>
                    <p class="text-justify">INFORMATION YOU PROVIDE TO US – We may ask you to provide us with certain Protected Information. We may collect this information through various means and in various places through the Services, including account registration forms, Contact Us forms, or when you otherwise interact with us. When you sign up to use the Services, you create a user profile. We shall ask you to provide only such Protected Information which is for a lawful purpose connected with our Services and necessary to be collected by us for such purpose.
                    </p>
                    <p class="text-justify">
                        <b>The current data fields that might be requested for are:</b></p>
                        <ul>
                            <li>Email</li>
                            <li>Name</li>
                            <li>Address</li>
                            <li>Mobile Number</li>
                        </ul>
                        <p class="text-justify"><b>Your Passenger details:</b>
                        </p>
                        <ul>
                            <li>Email</li>
                            <li>Name</li>
                            <li>Address</li>
                            <li>Mobile Number</li>
                            <li>Designation</li>
                        </ul>
  </Row>
</Container>
</section>

<section className='five'>
  <Container>
<Row>
 <Col md="12"> 
 <h2 className='text-center'>Want to know more?
<br/>
Let’s get in touch</h2>
<p className='text-center'> <Link to="/contact"><button className='contact-btn'>Contact Us</button></Link></p>
</Col>
</Row>
  </Container>
  </section>

     </>
   )
 }
 
 export default Privacy