import { useRoutes } from "react-router-dom";


import Home from '../components/Home/Home'
import About from '../components/About/About'
import Faq from "../components/faq/Faq";
import Privacy from "../components/privacy/privacy";
import Contact from "../components/contact/Contact";
import Benefits from "../components/Benefits/Benefits";

function App() {
let routes = useRoutes([
    {
        path: "/",
        element: <Home />
        
    },
    {
      path: "home",
      element: <Home />
      
  },
    {
      path: "about",
      element: <About />
      
  },
  {
    path: "faq",
    element: <Faq />
    
},
{
  path: "privacy",
  element: <Privacy />
  
},
{
  path: "contact",
  element: <Contact />
  
},
{
  path: "benefits",
  element: <Benefits />
  
}
]);

return routes;
}
export default App;
// https://reactrouter.com/docs/en/v6/hooks/use-routes#useroutes